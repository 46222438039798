<!-- 上传组件 -->
<template>
  <div class="root">
    <el-upload class="uploader" :headers="token" :action="uploadurl" :limit="1" :on-success="onSuccess" :on-remove="remove" :file-list="fileList">
      <el-button size="small" type="primary">点击上传</el-button>
    </el-upload>
  </div>
</template>

<script>
export default {
    props: {
        fileList: { type: Array, default: () => [] }
    },
    data() {
        return {
            uploadurl: process.env.VUE_APP_BASE_API + 'admin/localUpload'
        }
    },
    computed: {
        token() { return { 'Authorization': 'Bearer ' + this.$store.getters.token } }
    },
    watch: {},
    created() {},
    mounted() {},
    methods: {
        onSuccess(res, file, fileList) {
            if (res.code === 0) {
                const List = fileList.map(i => { // 组织参数
                    return { name: i.name, size: i.size, suffix: i.response.data.suffix, filePath: i.response.data.path }
                })
                this.$emit('update:fileList', List)
            }
        },
        // 移除
        remove(file, fileList) {
            const List = fileList.map(i => { // 组织参数
                return { name: i.name, size: i.size, suffix: i.response.data.suffix, filePath: i.response.data.path }
            })
            this.$emit('update:fileList', List)
        }
    }
}
</script>

<style lang='scss' scoped>

</style>
