import tokenhelper from '@/utils/tokenhelper'
import { login, getPermission } from '@/api/login'
import { addDynamicRoutes } from '@/router'

const state = {
    token: tokenhelper.getToken(),
    userInfo: {},
    menus: [],
    permissions: []
}

const mutations = {
    setToken: (state, token) => {
        state.token = token
        tokenhelper.setToken(token)
    },
    setUserInfo: (state, userInfo) => {
        state.userInfo = userInfo
        localStorage.setItem('userInfo', JSON.stringify(userInfo))
    },
    setMenus: (state, menus) => {
        state.menus = menus
    },
    setPermissions: (state, permissions) => {
        state.permissions = permissions
    }
}

const actions = {
    // 登录
    async goLogin({ commit }, params) {
        const res = await login(params)
        if (res.code === 0) {
            commit('setToken', res.data.token)
            commit('setUserInfo', res.data)
        }
        return res
    },
    // 获取权限
    async getPermission({ commit }, params) {
        const res = await getPermission(params)
        if (res.code === 0) {
            const menus = res.data
            commit('setMenus', menus)
            addDynamicRoutes(menus)
        }
        return res
    },
    loginOut({ commit }) {
        commit('setToken', '')
        localStorage.removeItem('userInfo')
        tokenhelper.deleteToken()
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
