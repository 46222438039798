<!-- 组件说明 -->
<template>
  <div>
    <el-table :header-cell-style="{background:'rgb(245, 247, 250)'}" border fixed :data="loadData" style="width: 100%" @selection-change="handleSelectionChange">
      <!-- 多选框 -->
      <el-table-column v-if="selection" type="selection" width="55" :align="align" :selectable="selectableFun" />
      <!-- 文本数据渲染 -->
      <template v-for="item in headConfig">
        <el-table-column v-if="item.columnType==='slot'" :key="item.field" :fixed="item.fixed || false" :prop="item.field" :label="item.label" :width="item.width" :align="align">
          <template slot-scope="scope">
            <slot :name="item.slotName" :data="scope" />
          </template>
        </el-table-column>
        <el-table-column v-else :key="item.field" :prop="item.field" :label="item.label" :width="item.width" :align="align" />
      </template>
    </el-table>
  </div>
</template>

<script>
export default {
    props: {
        headConfig: {
            type: Array,
            default: () => []
        },
        loadData: {
            type: Array,
            default: () => []
        },
        align: {
            type: String,
            default: 'center'
        },
        selection: {
            type: Boolean,
            default: false
        },
        selectableFun: {
            type: Function,
            default: () => true
        }
    },
    methods: {
        // 当前选中项
        handleSelectionChange(val) {
            this.$emit('update:select-list', val)
        }
    }
}

</script>

<style lang='scss' scoped>
</style>
