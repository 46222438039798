import Vue from 'vue'
import VueRouter from 'vue-router'
import tokenhelper from '@/utils/tokenhelper'
import store from '@/store'
import { replace } from 'lodash'
Vue.use(VueRouter)

// 默认路由
const constantRoutes = [
    {
        path: '/login',
        component: () => import('@/views/account/login'),
        hidden: true,
        meta: { title: '登录' }
    }
]

const createRouter = () => new VueRouter({
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
})

const vueRouter = createRouter()

// 重置路由
export function resetRouter() {
    const newRouter = createRouter()
    vueRouter.matcher = newRouter.matcher
}

// 生成路由
function generateRoutes(menus = []) {
    const routes = {
        path: '/',
        component: () => import('@/layout/index.vue'),
        children: [],
        meta: {}
    }
    const rootRouter = {
        path: '/',
        redirect: process.env.VUE_APP_DesktopPath,
        name: '/home',
        icon: '',
        meta: {
            title: '首页'
        },
        component: () => import('@/views/index.vue')
    }
    routes.children.push(rootRouter)

    for (let i = 0; i < menus.length; i++) {
        if (menus[i].path) {
            const route = {
                name: menus[i].name,
                path: menus[i].path,
                component: () => import('@/views' + menus[i].path),
                meta: {
                    title: menus[i].title,
                    icon: menus[i].icon,
                    path: menus[i].path,
                    actions: menus[i].actions
                }
            }
            routes.children.push(route)
        }
    }

    routes.children.push({
        path: '*',
        component: () => import('@/views/404.vue'),
        meta: { title: 'NotFound' },
        hidden: true
    })

    return [routes]
}

// 添加路由
export function addDynamicRoutes(menus = []) {
    if (menus && menus.length > 0) {
        const dynamicRoutes = generateRoutes(menus)
        resetRouter()
        vueRouter.addRoutes(dynamicRoutes)
    }
}

// 路由全局守卫
vueRouter.beforeEach(async(to, from, next) => {
    const token = tokenhelper.getToken()
    if (token) {
        if (to.path === '/login') {
            goLogin(to, next)
        } else {
            const hasPermission = store.getters.menus && store.getters.menus.length > 0
            if (hasPermission) {
                next()
            } else {
                const res = await store.dispatch('user/getPermission')
                if (res.code === 0) {
                    next({ ...to, replace })
                } else {
                    goLogin(to, next)
                }
            }
        }
    } else {
        goLogin(to, next)
    }
})

function goLogin(to, next) {
    if (to.path === '/login') {
        next()
    } else {
        next(`/login`)
    }
}

export function goLogout() {
    vueRouter.push('/login')
}

// 设置缓存视图
// function setCachedViews () {
//     let sessionStorageTabs = sessionStorage.getItem('tabs')
//     sessionStorageTabs = sessionStorageTabs ? JSON.parse(sessionStorageTabs) : []
//     const cachedViews = sessionStorageTabs.map(p => p.name)
//     store.commit('tabsView/set_cached_view', cachedViews)
// }

// 导出路由实例
export default vueRouter
// ---------------------------------------------------------
