<template>
  <section class="content" style="height:100%;">
    <el-container class="box no-border">
      <el-header class="box-header" style="padding-bottom:15px" height="auto">
        <slot name="header" />
      </el-header>
      <el-main>
        <slot />
      </el-main>
      <el-footer height="50px">
        <slot name="footer" />
      </el-footer>
    </el-container>

  </section>
</template>

<script>
export default {
    name: 'Container'
}
</script>
