import request from '@/utils/request'

// 登录
export const login = (params) => {
    return request.post('admin/login', params)
}

// 获取菜单
export const getPermission = (params) => {
    return request.post('admin/getPermission', params)
}
