import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import request from '@/utils/request'
import lodash from 'lodash'

import element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/index.css'

import '@/utils/components' // 全局组件注册

Vue.config.productionTip = false

Vue.prototype.$http = request
Vue.prototype.$_ = lodash

Vue.use(element, {
    size: 'mini'
})

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
