<template>
  <el-container style="height:100%">
    <el-aside :width="leftWidth">
      <div class="box box-no-right-radius" style="height:100%">
        <div class="box-header with-border">
          <h3 class="box-title">
            {{ leftTitle }}
          </h3>
        </div>
        <div class="box-body" style="min-height:550px">
          <slot name="leftBody" />
        </div>
      </div>
    </el-aside>
    <el-main>
      <div class="box box-no-left-radius" style="height:100%">
        <div class="box-header with-border">
          <slot name="rightHeader" />
        </div>
        <div class="box-body" style="min-height:550px">
          <slot />
        </div>
        <el-footer v-if="showFooter" height="40px">
          <slot name="rightFooter" />
        </el-footer>
      </div>
    </el-main>
  </el-container>
</template>

<script>
export default {
    name: 'LeftRightPanel',
    props: {
        leftTitle: {
            type: String,
            default: ''
        },
        leftWidth: {
            type: String,
            default: '15%'
        },
        showFooter: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {

        }
    }
}
</script>

<style scoped>
.el-aside{
  height: auto;
  overflow: hidden;
  padding: 0 10px 10px 10px;
}

.el-main{
  padding: 0 10px 10px 10px;
}

.box-no-right-radius{
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.box-no-left-radius{
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

</style>
