<!-- 公共弹窗组件 -->
<template>
  <div class="Dialog">
    <el-dialog
      :title="data.title || '提示'"
      :visible.sync="data.switch"
      :width="data.width || '30%'"
      :top="data.top || '7%'"
      :close-on-click-modal="false"
      @close="close"
      @open="open"
      @closed="closed"
    >
      <slot />
      <span slot="footer" class="dialog-footer">
        <el-button
          v-if="!(data.diableClose)"
          size="mini"
          type="warning"
          @click="Submit('close')"
        >{{ data.closeText || '取消' }}</el-button>
        <el-button
          v-if="!(data.disableSubmit)"
          size="mini"
          type="success"
          @click="Submit('submit')"
        >{{ data.submitText || '确定' }}</el-button>

      </span>
    </el-dialog>
  </div>
</template>

<script>
// 导入
// import 'xx' from 'path;
export default {
    // 注册组件
    components: {},
    props: {
        data: { type: Object, default: () => { } }, // dialog配置
        open: { type: Function, default: () => {} }, // open回调
        closed: { type: Function, default: () => {} } // open回调
    },
    // 存放数据
    data() {
        return {}
    },
    methods: {
        // 处理操作
        Submit(sign) {
            if (sign === 'close') {
                this.data.switch = false
            } else if (sign === 'submit') {
                this.$emit('submit', false)
            } else if (sign === 'save') {
                this.$emit('submit', 'save')
            } else if (sign === 'back') {
                this.$emit('submit', 'back')
            }
        },
        // close() {
        //     if (this.$parent.$refs.form) {
        //         this.$parent.$refs.form.resetFields()
        //     }
        // }
        close() { if (this.$parent.resetFields) { this.$parent.resetFields() } }
    }
    /** 文档：
     * 入参：DialogData  类型：对象    Diglog的配置项
     * > Dialog提示文字：title         类型 ：字符串
     * > Dialog展示宽度：width         类型 ：字符串
     * > Dialog控制开关：switch        类型 ：布尔
     * > closeText  关闭弹框按钮的文字 类型 ：字符串
     * > submitText 提交弹框按钮的文字 类型 ：字符串
     */
}
</script>

<style lang='scss' ></style>
