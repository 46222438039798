const getters = {
    cachedViews: state => state.tabsView.cachedViews,
    token: state => state.user.token,
    menus: state => state.user.menus,
    permissions: state => state.user.permissions,
    userInfo: state => {
        if (!state.userInfo) {
            const userInfo = JSON.parse(localStorage.getItem('userInfo'))
            state.userInfo = userInfo
            if (state.userInfo.avatar) {
                state.userInfo.avatar = process.env.VUE_APP_BASE_API + state.userInfo.avatar
            }
        }
        return state.userInfo
    },
    loading: state => state.main.loading
}

export default getters
