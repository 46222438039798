export default {
    namespaced: true,
    state: {
        loading: false
    },
    mutations: {
        updateLoading: (state, now) => {
            state.loading = now
        }
    }
}
